@use 'utils/primeng/button';
@use 'variables/sizes';

.p-button {
  $padding: sizes.$size-10;
  $border-radius: sizes.$size-6;

  border-radius: $border-radius;
  padding: $padding;

  &.p-button-icon-with-label {
    $space: sizes.$size-14;

    @include button.icon-with-label($space: $space);

    &.p-button-lg {
      $space: sizes.$size-18;

      @include button.icon-with-label($space: $space);
    }

    &.p-button-sm {
      $space: sizes.$size-8;

      @include button.icon-with-label($space: $space);
    }
  }

  &.p-button-icon-only {
    $width: sizes.$size-40;
    $padding: sizes.$size-12 0;

    border-radius: 100%;

    @include button.content($width: $width, $padding: $padding);
  }
}
