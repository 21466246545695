@mixin action-cell() {
  .p-datatable-tbody > tr > td,
  .p-datatable-thead > tr > th {
    &.column-type-actions {
      position: sticky;
      right: 0;
      padding: 0;
      text-align: end;
    }
  }
}

@mixin body-cell($border-color: null, $border-width: null) {
  .p-datatable-tbody > tr > td {
    border-color: $border-color;
    border-width: $border-width;
  }
}

@mixin filler-cell() {
  .p-datatable-tbody > tr > td,
  .p-datatable-thead > tr > th {
    &.column-type-filler {
      width: 100%;
      padding: 0;
    }
  }
}

@mixin select-cell() {
  .p-datatable-tbody > tr > td,
  .p-datatable-thead > tr > th {
    &.column-type-select {
      text-align: center;
    }
  }
}
