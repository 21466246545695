/* nunito-regular - latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
  src:
    url('/assets/fonts/Nunito/nunito-v26-latin-ext-regular.woff2')
      format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
      url('/assets/fonts/Nunito/nunito-v26-latin-ext-regular.ttf')
      format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

/* nunito-italic - latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: Nunito;
  font-style: italic;
  font-weight: 400;
  src:
    url('/assets/fonts/Nunito/nunito-v26-latin-ext-italic.woff2')
      format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
      url('/assets/fonts/Nunito/nunito-v26-latin-ext-italic.ttf')
      format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

/* nunito-700 - latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: Nunito;
  font-style: normal;
  font-weight: 700;
  src:
    url('/assets/fonts/Nunito/nunito-v26-latin-ext-700.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
      url('/assets/fonts/Nunito/nunito-v26-latin-ext-700.ttf')
      format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
