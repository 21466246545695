@use 'variables/sizes';
@use 'variables/colors';
@use 'utils/primeng/fieldset';

.p-fieldset {
  $border: 1px solid colors.$dark-200;
  $border-radius: sizes.$size-3;
  $padding: sizes.$size-12;
  $legend-background: colors.$white;
  $legend-border: none;
  $legend-padding: sizes.$size-8;
  $legend-font-size: sizes.$size-14;

  @include fieldset.content(
    $border: $border,
    $border-radius: $border-radius,
    $padding: $padding
  );
  @include fieldset.legend(
    $background: $legend-background,
    $border: $legend-border,
    $padding: $legend-padding,
    $font-size: $legend-font-size
  );
}
