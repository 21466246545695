@use 'variables/sizes';
@use 'utils/primeng/calendar';

.p-datepicker {
  $font-size: sizes.$size-14;
  $time-picker-label-font-size: sizes.$size-14;
  $time-picker-button-size: sizes.$size-24;
  $table-cell-padding: 0;
  $buttonbar-padding: sizes.$size-8;

  @include calendar.content($font-size: $font-size);
  @include calendar.table-cell($padding: $table-cell-padding);
  @include calendar.time-picker-label($font-size: $time-picker-label-font-size);
  @include calendar.time-picker-button($size: $time-picker-button-size);
  @include calendar.buttonbar($padding: $buttonbar-padding);
}

.datepicker-position-center {
  position: fixed !important;
  display: block !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}
