@mixin content(
  $padding: null,
  $margin: null,
  $border: null,
  $border-radius: null
) {
  border: $border;
  border-radius: $border-radius;
  padding: $padding;
  margin: $margin;
}

@mixin legend(
  $padding: null,
  $margin: null,
  $font-size: null,
  $border: null,
  $background: null
) {
  .p-fieldset-legend {
    float: none;
    width: unset;
    padding: $padding;
    margin: $margin;
    font-size: $font-size;
    border: $border;
    background: $background;
  }
}
