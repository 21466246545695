@mixin content {
  background-color: transparent;
  border: 0;
  font-size: inherit;
  padding: 0;
  color: inherit;
  text-decoration: underline;
  display: inline-flex;
}

@mixin hover($text-color: null) {
  &:hover {
    color: $text-color;
  }
}

@mixin focus($outline-color: null) {
  &:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.1rem $outline-color;
  }
}
