@mixin panel-items($margin: null, $padding: null) {
  .p-dropdown-items {
    margin: $margin;
    padding: $padding;
  }
}

@mixin label($font-size: null) {
  .p-dropdown-label {
    font-size: $font-size;
  }
}
