/* You can add global styles to this file, and also import other style files */

@use 'variables/colors';
@use 'variables/sizes';
@use 'variables/variables';

@use 'libs/primeng';
@use 'libs/icomoon';
@use 'libs/bootstrap';
@use 'libs/bootstrap-icons';
@use 'libs/fonts';
@use 'libs/quill';

@use 'components/component';
@use 'components/link';
@use 'components/menubar';
@use 'components/italic';
@use 'components/table';
@use 'components/paginator';
@use 'components/button';
@use 'components/tab-menu';
@use 'components/dropdown';
@use 'components/card';
@use 'components/divider';
@use 'components/confirmation';
@use 'components/multiselect';
@use 'components/badge';
@use 'components/calendar';
@use 'components/input-icon';
@use 'components/menu';
@use 'components/fieldset';
@use 'components/auto-complete';
@use 'components/messages';

:root {
  --font-family: variables.$font-family;
}

body,
html {
  font-family: variables.$font-family;
  color: colors.$dark-700;
}

body {
  $background-color: colors.$dark-100;
  $text-color: colors.$body-text-color;
  $font-size: sizes.$size-14;

  font-size: $font-size;
  margin: 0;
  color: $text-color;
  background-color: $background-color;
}
