@mixin content($font-size: null) {
  .p-datepicker-calendar {
    font-size: $font-size;
  }
}

@mixin table-cell($padding: null) {
  table td {
    padding: $padding;
  }
}

@mixin time-picker-button($size: null) {
  .p-timepicker button {
    width: $size;
    height: $size;
  }
}

@mixin time-picker-label($font-size: null) {
  .p-timepicker span {
    font-size: $font-size;
  }
}

@mixin buttonbar($padding: null) {
  .p-datepicker-buttonbar {
    padding: $padding;
  }
}
