@mixin list($padding: null, $margin: null) {
  .p-tabmenu-nav {
    padding: $padding;
    margin: $margin;
  }
}

@mixin link-active($color: null) {
  .p-menuitem-link-active {
    color: $color;
  }
}
