$white: #fff;

$red: hsl(356% 95% 46%);
$red-50: hsl(0% 100% 98%);
$red-100: hsl(4% 100% 90%);
$red-500: hsl(4% 100% 60%);

$orange-500: hsl(30% 100% 48%);

$green-500: hsl(122% 39% 49%);

$dark: hsl(0% 0% 0%);
$dark-50: hsl(0% 0% 98%);
$dark-100: hsl(0% 0% 96%);
$dark-200: hsl(0% 0% 93%);
$dark-300: hsl(0% 0% 88%);
$dark-400: hsl(0% 0% 74%);
$dark-500: hsl(0% 0% 62%);
$dark-600: hsl(0% 0% 46%);
$dark-700: hsl(0% 0% 38%);
$dark-800: hsl(0% 0% 26%);
$dark-900: hsl(0% 0% 13%);

$blue-50: hsl(204% 83% 98%);
$blue-100: hsl(206% 89% 89%);
$blue-200: hsl(207% 90% 80%);
$blue-500: hsl(207% 90% 72%);
$blue-600: hsl(206% 76% 46%);

$primary: var(--primary-color);
$primary-50: hsl(122% 39% 98%);
$primary-100: hsl(122% 39% 88%);
$primary-200: hsl(123% 39% 78%);
$primary-300: hsl(123% 38% 68%);
$primary-500: hsl(122% 39% 49%);
$primary-600: hsl(122% 39% 42%);

$danger: $red;
$body-text-color: $dark-700;
