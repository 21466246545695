@use 'utils/primeng/table';
@use 'variables/colors';

.p-datatable-table {
  $body-cell-border-color: colors.$dark-200;
  $body-cell-border-width: 0 0 1px 0;

  @include table.body-cell(
    $border-color: $body-cell-border-color,
    $border-width: $body-cell-border-width
  );
  @include table.filler-cell;
  @include table.action-cell;
  @include table.select-cell;
}
