@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?zfedkn');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?zfedkn#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?zfedkn') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?zfedkn') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?zfedkn##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-dna {
  &:before {
    content: $icon-dna; 
  }
}
.icon-vials-solid {
  &:before {
    content: $icon-vials-solid; 
  }
}
.icon-microscope-solid {
  &:before {
    content: $icon-microscope-solid; 
  }
}

