@mixin icon-with-label($space) {
  display: flex;
  align-items: center;

  .p-button-label {
    margin-left: $space;
  }
}

@mixin content(
  $padding: null,
  $background-color: null,
  $text-color: null,
  $width: null,
  $border: null
) {
  background: $background-color;
  padding: $padding;
  color: $text-color;
  width: $width;
  border: $border;
  line-height: 1;
}
