@use 'utils/primeng/dropdown';
@use 'variables/sizes';

.p-dropdown {
  $label-font-size: sizes.$size-14;

  @include dropdown.label($font-size: $label-font-size);
}

.p-dropdown-panel {
  $panel-items-margin: 0;
  $panel-items-padding: 0;

  @include dropdown.panel-items(
    $margin: $panel-items-margin,
    $padding: $panel-items-padding
  );
}
