@use 'utils/primeng/paginator';
@use 'variables/sizes';

.p-paginator {
  $padding: sizes.$size-16;
  $element-border-radius: 50%;

  padding: $padding;

  @include paginator.element($border-radius: $element-border-radius);
}
