$size-1: 0.0625rem;
$size-2: 0.125rem;
$size-3: 0.188rem;
$size-4: 0.25rem;
$size-6: 0.375rem;
$size-8: 0.5rem;
$size-10: 0.625rem;
$size-12: 0.75rem;
$size-14: 0.875rem;
$size-16: 1rem;
$size-18: 1.125rem;
$size-22: 1.375rem;
$size-24: 1.5rem;
$size-28: 1.75rem;
$size-32: 2rem;
$size-40: 2.5rem;
$size-48: 3rem;
$size-64: 4rem;
$size-112: 7rem;
$size-300: 18.75rem;
$size-384: 24rem;
$size-464: 29rem;
$size-480: 30rem;

$sidebar-width: 16rem;
$navbar-height: 4.375rem;

$aside-font-size: $size-14;
