@mixin content($border: null, $background-color: null) {
  background: $background-color;
  border: $border;
  padding: 0;
}

@mixin submenu($padding: null, $submenu-border: null, $width: null) {
  .p-submenu-list {
    padding: $padding;
    border: $submenu-border;
    width: $width;
  }
}

@mixin root-list($margin: null) {
  .p-menubar-root-list {
    margin: $margin;
  }
}

@mixin item-link($text-color: null, $hover-text-color: null) {
  .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    color: $text-color;

    .p-menuitem-text {
      color: inherit !important;
    }

    &:not(.p-disabled):hover {
      background-color: rgb(0 0 0 / 10%);
      color: $hover-text-color;
    }
  }
}
