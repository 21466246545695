@use 'variables/sizes';
@use 'utils/primeng/auto-complete';

.p-autocomplete-panel {
  $panel-items-margin: 0;
  $panel-items-padding: 0;

  @include auto-complete.panel-items(
    $margin: $panel-items-margin,
    $padding: $panel-items-padding
  );
}
