@use 'utils/primeng/tab-menu';
@use 'variables/sizes';
@use 'variables/colors';

.p-tabmenu {
  $list-padding: 0;
  $list-margin: 0;
  $link-active-color: colors.$primary;

  @include tab-menu.list($padding: $list-padding, $margin: $list-margin);
  @include tab-menu.link-active($color: $link-active-color);
}
