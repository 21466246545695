@use 'variables/colors';
@use 'utils/link';

.link {
  $hover-text-color: colors.$dark;
  $focus-outline-color: colors.$primary;

  @include link.content;
  @include link.hover($text-color: $hover-text-color);
  @include link.focus($outline-color: $focus-outline-color);
}

a {
  text-decoration: none;
  color: initial;
}
