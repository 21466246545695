@mixin message-icon() {
  .p-message-icon {
    display: flex;
  }
}

@mixin close() {
  .p-message-close {
    margin-left: auto;
  }
}
