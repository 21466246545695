@use 'variables/colors';
@use 'utils/primeng/menubar';

.p-menubar {
  $menubar-border: none;
  $menubar-background-color: none;
  $submenu-padding: 0;
  $submenu-border: none;
  $submenu-width: 100%;
  $root-list-margin: 0;

  @include menubar.content(
    $border: $menubar-border,
    $background-color: $menubar-background-color
  );
  @include menubar.submenu($submenu-padding, $submenu-border, $submenu-width);
  @include menubar.item-link(
    $text-color: colors.$blue-100,
    $hover-text-color: colors.$blue-200
  );
  @include menubar.root-list($root-list-margin);
}
